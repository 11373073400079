<script lang="ts">
	// Import Sentry (for side effects)
	import '$lib/sentry';
	// Browser support
	import { browserIsSupported } from '$lib/utils/browser-compatibility';
	// Import fonts
	import '$/assets/fonts/iosevka-square-broking/iosevka-square-broking.css';
	import FontBoldSrc from '$/assets/fonts/iosevka-square-broking/woff2/iosevka-square-broking-bold.woff2';
	import FontExtendedHeavySrc from '$/assets/fonts/iosevka-square-broking/woff2/iosevka-square-broking-extendedheavy.woff2';
	import FontHeavySrc from '$/assets/fonts/iosevka-square-broking/woff2/iosevka-square-broking-heavy.woff2';
	import FontRegularSrc from '$/assets/fonts/iosevka-square-broking/woff2/iosevka-square-broking-regular.woff2';
	// Import global CSS
	import '$/app.windi.css';
	// Import Windi
	import 'virtual:windi.css';
	// Manual Dark Mode support
	import SvelteTheme from 'svelte-themes/SvelteTheme.svelte';
	// Svelte
	import { goto } from '$app/navigation';
	import { onMount } from 'svelte';

	const websiteTitle = 'Square Broking' as const;

	onMount(() => {
		if (!browserIsSupported()) void goto('/unsupported-browser');
	});
</script>

<svelte:head>
	<link href={FontRegularSrc} type="font/woff2" rel="preload" as="font" crossorigin="" />
	<link href={FontExtendedHeavySrc} type="font/woff2" rel="preload" as="font" crossorigin="" />
	<link href={FontBoldSrc} type="font/woff2" rel="preload" as="font" crossorigin="" />
	<link href={FontHeavySrc} type="font/woff2" rel="preload" as="font" crossorigin="" />
	<title>{websiteTitle}</title>
</svelte:head>

<SvelteTheme storageKey="squareBrokingColorTheme" attribute="class" />
<slot />
